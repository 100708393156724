// useSocket.js
import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { API_BASE_URL } from "../services";

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const shouldReconnectRef = useRef(true); // Default to true to allow reconnections

  useEffect(() => {
    const socketIo = io(API_BASE_URL, {
      withCredentials: true,
      transports: ["websocket"],
      autoConnect: true, // Enable automatic connection
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    setSocket(socketIo);

    // Log connection and disconnection events
    socketIo.on("connect", () => {
      console.log("Connected to the server");
    });

    socketIo.on("disconnect", (reason) => {
      console.log("Disconnected from server:", reason);
      if (!shouldReconnectRef.current) {
        console.log("Reconnection disabled, disconnecting socket.");
        socketIo.disconnect();
      }
    });

    // Cleanup on component unmount
    return () => {
      shouldReconnectRef.current = false; // Prevent reconnection on unmount
      socketIo.disconnect();
    };
  }, []);

  return {
    socket,
    connect: () => {
      shouldReconnectRef.current = true;
      socket?.connect();
    },
    disconnect: () => {
      shouldReconnectRef.current = false;
      socket?.disconnect();
    },
  };
};

export default useSocket;
