// services.js

// export const API_BASE_URL = "http://localhost:8000"; // Replace with your actual API base URL
export const API_BASE_URL = "https://tictactoe-api.sankalpmukim.dev"; // Replace with your actual API base URL

// Custom Error Class to include status code
class ApiError extends Error {
  constructor(message, status) {
    super(message);
    this.name = "ApiError";
    this.status = status;
  }
}

// Helper function to handle API responses
const handleResponse = async (response) => {
  const data = await response.json();
  if (!response.ok) {
    throw new ApiError(data.error || "An error occurred", response.status);
  }
  return data;
};

// Create Game
export const createGame = async () => {
  while (true) {
    try {
      const response = await fetch(`${API_BASE_URL}/create-game`, {
        method: "POST",
        credentials: "include",
      });
      return await handleResponse(response);
    } catch (error) {
      console.error("Error creating game:", error);
      if (
        error instanceof ApiError &&
        error.status === 400 &&
        error.message.includes("in a game")
      ) {
        await new Promise((resolve) => setTimeout(resolve, 1));
        continue;
      } else {
        throw error;
      }
    }
  }
};

// Join Game
export const joinGame = async (gameCode) => {
  while (true) {
    try {
      const response = await fetch(`${API_BASE_URL}/join-game`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ gameCode }),
        credentials: "include",
      });
      return await handleResponse(response);
    } catch (error) {
      console.error("Error joining game:", error);
      if (
        error instanceof ApiError &&
        error.status === 400 &&
        error.message.includes("in a game")
      ) {
        await new Promise((resolve) => setTimeout(resolve, 1));
        continue;
      } else {
        throw error;
      }
    }
  }
};

// Get Game State
export const getGameState = async (gameCode) => {
  try {
    const response = await fetch(`${API_BASE_URL}/game-state/${gameCode}`, {
      credentials: "include",
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("Error getting game state:", error);
    throw error;
  }
};

// Refresh Session
export const refreshSession = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/refresh-session`, {
      method: "POST",
      credentials: "include",
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("Error refreshing session:", error);
    throw error;
  }
};

// Make Move
export const makeMove = async (gameCode, position) => {
  try {
    const response = await fetch(`${API_BASE_URL}/make-move/${gameCode}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ position }),
      credentials: "include",
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("Error making move:", error);
    throw error;
  }
};
