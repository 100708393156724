import { useState } from "react";

function IntroScreen({ onCreateGame, onJoinGame }) {
  const [joinCode, setJoinCode] = useState("");

  const handleJoinGame = (e) => {
    e.preventDefault();
    onJoinGame(joinCode);
  };

  return (
    <div className="game-setup">
      <button onClick={onCreateGame}>Create New Game</button>
      <form onSubmit={handleJoinGame}>
        <input
          type="text"
          value={joinCode}
          onChange={(e) => setJoinCode(e.target.value)}
          placeholder="Enter game code"
        />
        <button type="submit">Join Game</button>
      </form>
    </div>
  );
}

export default IntroScreen;
